import { useEffect, useState } from "react";
import "../../assets/css/DockDoor/dockDoor.css";
import {
  dockDoorStatusColor,
  findDockDoorPosition,
} from "../../util/helper/helperFunctions";

const DockIcon = ({ dockMode, dockData, plantConfig }) => {
  const groupDockDoorWithPosition = (dockData) => {
    let groupedData = {};
    dockData.map((pallet, index) => {
      if (!groupedData[pallet.POSITION]) {
        groupedData[pallet.POSITION] = [];
      }
      groupedData[pallet.POSITION].push(pallet);
    });
    let dockDoorPosition = findDockDoorPosition(plantConfig);
    if (dockDoorPosition == "DOWN") {
      let reversedObject = Object.values(groupedData).reverse();
      return reversedObject;
    } else {
      return groupedData;
    }
  };

  const createDockDoorName = (dockDoorName) => {
    let temp = dockDoorName.split(".")[1];
    if (temp[0] == "0") {
      temp = temp.substring(1);
    }
    return temp;
  };

  if (plantConfig) {
    if (!dockMode) {
      //SIMPLE MODE
      return (
        <div className="dock-container">
          {findDockDoorPosition(plantConfig) == "UP" ? (
            <div className="dock-top-section">
              <div
                className={`dock-top-section-operation ${
                  dockData[0].DOOR_IN_MANUAL === 1
                    ? "dock-door-manual"
                    : "dock-door-auto"
                }`}
              >
                {dockData[0].DOOR_IN_MANUAL === 1 ? "M" : "A"}
              </div>
              <div className="dock-top-section-number">
                {createDockDoorName(dockData[0]["DOCK"])}
              </div>
            </div>
          ) : null}
          <div className="dock-bottom-section">
            {dockData &&
              Object.values(groupDockDoorWithPosition(dockData)).map(
                (value, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: dockDoorStatusColor(
                          value[0].STATUS,
                          "SIMPLE"
                        ),
                      }}
                      className={`dock ${
                        value.length == 1 ? "dock-small" : "dock-big"
                      }`}
                    ></div>
                  );
                }
              )}
          </div>
          {findDockDoorPosition(plantConfig) == "DOWN" ? (
            <div className="dock-top-section">
              <div className="dock-top-section-number">
                {createDockDoorName(dockData[0]["DOCK"])}
              </div>
              <div
                className={`dock-top-section-operation ${
                  dockData[0].DOOR_IN_MANUAL === 1
                    ? "dock-door-manual"
                    : "dock-door-auto"
                }`}
              >
                {dockData[0].DOOR_IN_MANUAL === 1 ? "M" : "A"}
              </div>
            </div>
          ) : null}
        </div>
      );
    } else {
      //COMPLEX MODE
      return (
        <div className="dock-container">
          {findDockDoorPosition(plantConfig) == "UP" ? (
            <div className="dock-top-section">
              <div
                className={`dock-top-section-operation ${
                  dockData[0].DOOR_IN_MANUAL === 1
                    ? "dock-door-manual"
                    : "dock-door-auto"
                }`}
              >
                {dockData[0].DOOR_IN_MANUAL === 1 ? "M" : "A"}
              </div>
              <div className="dock-top-section-number">
                {createDockDoorName(dockData[0]["DOCK"])}
              </div>
            </div>
          ) : null}
          <div className="dock-bottom-section">
            {dockData &&
              Object.values(groupDockDoorWithPosition(dockData)).map(
                (value, index) => {
                  if (value.length == 1) {
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: dockDoorStatusColor(
                            value[0].STATUS,
                            "COMPLEX"
                          ),
                        }}
                        className={`dock dock-small`}
                      >
                        s
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="dock dock-big">
                        <div
                          style={{
                            backgroundColor: dockDoorStatusColor(
                              value[0].STATUS,
                              "COMPLEX"
                            ),
                          }}
                          className="dock dock-big-complex-left"
                        >
                          s
                        </div>
                        <div
                          style={{
                            backgroundColor: dockDoorStatusColor(
                              value[1].STATUS,
                              "COMPLEX"
                            ),
                          }}
                          className="dock dock-big-complex-right"
                        >
                          s
                        </div>
                      </div>
                    );
                  }
                }
              )}
          </div>
          {findDockDoorPosition(plantConfig) == "DOWN" ? (
            <div className="dock-top-section">
              <div className="dock-top-section-number">
                {createDockDoorName(dockData[0]["DOCK"])}
              </div>
              <div
                className={`dock-top-section-operation ${
                  dockData[0].DOOR_IN_MANUAL === 1
                    ? "dock-door-manual"
                    : "dock-door-auto"
                }`}
              >
                {dockData[0].DOOR_IN_MANUAL === 1 ? "M" : "A"}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }
};

export default DockIcon;
