import { useContext, useMemo } from "react";
import "../../assets/css/YardView/YardDockDoor.css";
import YardDockIcon from "./YardDockIcon";
import { Marker } from "react-leaflet";
import { convertReactComponentToHtmlIcon } from "../../util/helper/helperFunctions";
import { AppContext } from "../../Context/Context";

const YardDockDoors = ({
  yardData,
  legendColors,
  setIsYardDetailModalOpen,
  setYardDetailModalData,
}) => {
  let { plantConfig } = useContext(AppContext);
  let convertedDoorDockData = yardData;

  const RenderYardDockDoors = useMemo(() => {
    if (convertedDoorDockData && plantConfig) {
      let { groupOffset, marker } = plantConfig.yard.dockDoors;
      let XYPosition = [groupOffset.x, groupOffset.y];
      return convertedDoorDockData.map((markerData, index) => {
        let { xCoord, yCoord, xLength, yLength } = markerData;
        let isHorizontal = xLength > yLength;
        let { XCoord, YCoord, XLength, YLength } = getScaledHeightWidth(
          marker.scale,
          xCoord,
          yCoord,
          xLength,
          yLength
        );
        let reactIcon = convertReactComponentToHtmlIcon(
          <YardDockIcon
            dockData={markerData}
            legendColors={legendColors}
            key={index}
          />,
          `yard-dock-door ${isHorizontal ? "horizontal" : "vertical"}`,
          [XLength, YLength],
          XYPosition
        );
        return (
          <Marker
            type="yard-dock"
            data={markerData}
            eventHandlers={{
              click: (e) => {
                setIsYardDetailModalOpen(true);
                setYardDetailModalData(markerData);
              },
            }}
            key={index}
            riseOnHover={false}
            position={[YCoord + YLength, XCoord]}
            icon={reactIcon}
          ></Marker>
        );
      });
    }
  }, [convertedDoorDockData, plantConfig]);

  return (
    <div className={`yard-dock-door-container`}>
      {yardData && RenderYardDockDoors}
    </div>
  );
};

function getScaledHeightWidth(scale, xCoord, yCoord, xLength, yLength) {
  let scaleWidth = scale.x;
  let scaleHeight = scale.y;
  let isHorizontal = xLength > yLength;
  let XLength = xLength * scaleWidth;
  return {
    XCoord: xCoord * scaleWidth,
    YCoord: yCoord * scaleHeight,
    XLength,
    YLength: yLength * scaleHeight,
  };
}

export default YardDockDoors;
